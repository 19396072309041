import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import Title from '../../__atoms/title';
import Button from '../../__atoms/button';
import ExpandedContent from '../../__molecules/expanded-content';
import { IconShare, IconWhatsApp } from '../../__icons';
import { urlHttpCheck } from '../../../utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule FaqList
 *
 * <!-- TODO: add a description here! -->
 */
function FaqList(props) {
  const {
    className = '',
    title = null,
    faqContent = {},
    labelSize = null,
    invertArrow = false,
    type = null,
    locale = 'pt-BR',
    ...other
  } = props;

  const urlWhats = 'https://api.whatsapp.com/send?1=pt_BR&phone=55';
  const textWhats = '&text=Necessito de mais informações';
  const mailto = 'mailto:';

  const { items } = faqContent;

  return (
    <div className={`${css['faq-list-container']} ${className}`} {...other} data-type={type}>
      {title && (
        <Title level={2} variant={2} className={css['faq-list-title']}>
          {title}
        </Title>
      )}

      <ul className={css['faq-list-container']}>
        {items?.map((item, index) => (
          <li key={`faq-list-item-${index}`} className={css['faq-list__item']}>
            <ExpandedContent invertArrow={invertArrow} labelSize={labelSize} label={item.title}>
              {Array.isArray(item.content) ? (
                <div className={css['faq-list__items-container']}>
                  {item.content.map((content, contentIndex) => (
                    <div key={`faq-list-item-container-${index}-${contentIndex}`} className={css['faq-list__item-container']}>
                      {content.title && <span className={css['faq-list__item-title']}>{content.title}</span>}
                      {(content.companyName  && locale === 'es-AR') &&
                        <span className={css['faq-list__item-info']}>
                          Razón Social: {content.companyName}
                        </span>
                      }
                      {(content.cuit && locale === 'es-AR') &&
                        <span className={css['faq-list__item-info']}>
                          CUIT: {content.cuit}
                        </span>
                      }
                      {content.description &&
                        <span className={css['faq-list__item-info']}>
                          {content.description}
                        </span>
                      }
                      {content.street &&
                        <span className={css['faq-list__item-info']}>
                          {content.street}, {content.city}
                        </span>
                      }
                      {content.state &&
                        <span className={css['faq-list__item-info']}>
                          {content.state} {locale === 'pt-BR' && `, ${content.uf}, CEP ${content.zipcode}`}
                        </span>
                      }
                      {content.contactEmail && <a href={mailto.concat(content?.contactEmail)} className={css['faq-list__item-info']}>{content.contactEmail}</a>}
                      {content.site &&
                        <a href={urlHttpCheck(content.site)} className={css['faq-list__item-info']}>
                          {content.site}
                          <div className={css['icon-container']}>
                            <IconShare/>
                          </div>
                        </a>
                      }
                      {(content.phone || content.whatsapp || content.city || content.state) &&
                        <div className={css['bottom-container']}>
                          {(content.phone || content.whatsapp) &&
                            <div>
                              {content.phone && <span className={css['faq-list__item-info']}>{content.phone}</span>}
                              {
                                content.whatsapp &&
                                  <span className={css['faq-list__item-info']}>
                                    {content.whatsapp}
                                    <a href={urlWhats.concat(content.whatsapp).concat(textWhats)} className={css['icon-container']}>
                                      <IconWhatsApp/>
                                    </a>
                                  </span>
                              }
                            </div>
                          }

                          {(content.city && content.state) &&
                            <div className={css['button-container']}>
                              <Button
                                link={content.mapLink}
                                color='ghost-2'
                              >
                                {content.buttonLabel}
                              </Button>
                            </div>
                          }
                        </div>
                      }
                      {content.more && <span className={css['faq-list__item-more']}>{content.more}</span>}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={css['faq-list__item-text-container']}>
                  {/* eslint-disable-next-line */}
                  <ReactMarkdown className={css['faq-list__item-text']} children={item.content} rehypePlugins={[rehypeRaw]}/>
                  {/* <span className={css['faq-list__item-text']}>{item.content}</span> */}
                </div>
              )}
            </ExpandedContent>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FaqList;
