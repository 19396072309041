import React from 'react';

import Card from 'components/__molecules/card';
import Carousel from 'components/__molecules/carousel';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule CardsList
 *
 * A list of cards
 */
function CardsList(props) {
  const {
    className = '',
    cardsData,
    isHorizontal = false,
    variant = null,
    removeCarousel = false,
    ...other
  } = props;

  const settings = {
    speed: 500,
    arrows: false,
    dots: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: removeCarousel ? 9999 : 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: false,
          padding: 0
        }
      }
    ]
  };

  return <>
    <Carousel
      settings={settings}
      className={`${css["cards-list"]} ${className} ${variant ? css[variant] : ''} ${removeCarousel ? css["no-carousel"] : ''}`}
    >
      {cardsData &&
        cardsData.map((card, key) => {
          return <Card
            key={key}
            title={card.title}
            media={card.media}
            content={card.content}
            additionalContent={card.additionalContent}
            link={card.link || undefined}
            linkLabel={card.linkLabel}
            isExternal={card.isExternal}
            isDownload = {card.isDownload}
            isHorizontal = {isHorizontal}
          />;
        })
      }
    </Carousel>
  </>;

}

export default CardsList;
