import React from 'react';

import Wrapper from 'components/__atoms/wrapper';
import Section from 'components/__atoms/section';
import FaqList from 'components/__molecules/faq-list';

// loading the sass style fot the component
import css from './styles.module.scss';
/**
 * Organism FaqSection
 *
 * <!-- TODO: add a description here! -->
 */
function FaqSection (props) {

  const {
    spacingTop = "6",
    spacingBottom = "6",
    bgColor = "default",
    sectionTitle = "",
    sectionWidth = 'default',
    invertArrow = false,
    faqContent = null,
    type = null
  } = props;

  return (
      <Wrapper
        bgColor={bgColor}
        data-spacing-top={spacingTop}
        data-spacing-bottom={spacingBottom}
      >
        <Section hasGrid>
          {faqContent && (
            <FaqList
              data-grid-full-width={sectionWidth == 'full-width' ? true : false}
              data-grid-recess={sectionWidth == 'default' ? '1/3' : false}
              type={type}
              title={sectionTitle}
              faqContent={faqContent}
              invertArrow={invertArrow}
              className={css['faq-list-container']}
            />
          )}
        </Section>
      </Wrapper>
  );
}

export default FaqSection;
