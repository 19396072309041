import React from 'react';

import BannerListItem from '../__molecules/banner-list-item';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule BannerList
 *
 * A list of full width banners
 */
function BannerList(props) {
  const { className = '', banners = null, type = null, colorButton = 'light', ...other } = props;

  return (
    <>
      <ul className={`${css['banner-list']} ${className}`} {...other}>
        {banners &&
          banners.map((banner, index) => {
            return <BannerListItem bannerData={banner} key={index} type={type} colorButton={colorButton} />;
          })}
      </ul>
    </>
  );
}

export default BannerList;
