import React from 'react';

import Wrapper from 'components/__atoms/wrapper';
import Section from 'components/__atoms/section';
import Media from 'components/__molecules/media';

// loading the sass style fot the component
// import css from './styles.module.scss';

/**
 * Organism MediaSection
 *
 * <!-- TODO: add a description here! -->
 */
function MediaSection(props) {
  const {
    data = null,
    bgColor = '',
    spacingTop = 0,
    spacingBottom = 0,
    centralizeItem = false,
    isFullContentSize = false,
    containerSize = 'full-width'
  } = props;

  return (
    <>
      <Wrapper bgColor={bgColor} spacingTop={spacingTop} spacingBottom={spacingBottom}>
        <Section hasGrid fullScreen={containerSize === 'full-screen' ? true : false}>
          <Media
            type={data?.type}
            url={data?.url}
            alt={data?.alt}
            isFullContentSize={isFullContentSize}
            centralizeItem={centralizeItem}
            data-grid-full-width={containerSize === 'full-width' || containerSize === 'full-screen' ? true : undefined}
            data-grid-recess={containerSize === 'one-third' ? '1/3' : undefined}
          />
        </Section>
      </Wrapper>
    </>
  );
}

export default MediaSection;
