import React, { useState } from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ExpandedContent
 *
 * A button to expand the content of a child container
 */
function ExpandedContent(props) {

  const [isExpanded, setIsExpanded] = useState(false);
  const [currentHeight, setCurrentHeight] = useState('0px');

  const {
    className = '',
    children,
    label = null,
    contentClass = '',
    actionClass = '',
    invertArrow = false,
    labelSize = null,
    ...other
  } = props;

  function updateCurrentHeight() {
    currentHeight === '0px' ? setCurrentHeight('100%') : setCurrentHeight('0px');
    setIsExpanded(!isExpanded);
  }

  return (
    <>
      <div className={`${css['expanded-content-container']} ${className}`} {...other} data-is-expanded={isExpanded}>
        <div className={`${css['toggle-container']} ${actionClass}`}>
          <button
            className={`${css['toggle-container__action']}`}
            onClick={() => updateCurrentHeight()}
            data-invert-arrow={invertArrow}
          >
            <span className={css['toggle-button__label']} data-label-size={labelSize}>
              {label}
            </span>
            <svg
              className={css['toggle-button__arrow']}
              width="17"
              height="11"
              viewBox="0 0 17 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.5 0.5L8.5 9.5L0.499999 0.499999" stroke="#EB0A1E" />
            </svg>
          </button>
        </div>
        <div className={`${css['expanded-content']} ${contentClass}`} style={{ height: currentHeight }}>
          {children}
        </div>
      </div>
    </>
  );
}

export default ExpandedContent;
