import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import Title from '../../__atoms/title';
import { Button } from '../../__atoms/button';
import Media from '../../__molecules/media';
import AnchorLink from '../../__atoms/anchor-link';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule BannerListItem
 *
 * An item of the banner list
 */
function BannerListItem(props) {

  const {
    className = '',
    bannerData,
    variantTitle = 2,
    colorButton = "light",
    type = null,
    tag = null,
    ...other
  } = props;

  const Tag = tag ? tag : 'li';

  return (
    <Tag
      className={`${css['banner-list-item']} ${className}`}
      data-grid
      data-type={type}
      data-content-layout-type={bannerData?.contentLayoutType}
      {...other}>
      <div className={css['banner-image-container']}>
        <Media url={bannerData?.media?.url} alt={bannerData?.media?.alt} />
      </div>
      <div className={css['banner-content-container']}>
        <div>
          <Title variant={variantTitle} level={3} className={css['banner-content-container__title']}>
            {bannerData?.title}
          </Title>
          {(bannerData?.description || bannerData?.editorContent) &&
            <>
              <div className={css['banner-content-container__text']}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {bannerData?.description || bannerData?.editorContent}
                </ReactMarkdown>
              </div>
            </>
          }
        </div>
        {bannerData?.cta && (
          <div>
            {bannerData?.contentLayoutType == 'default' &&
              <Button className={css['banner-content-container__button']} color={colorButton} link={bannerData?.cta?.url}>
                {bannerData?.cta?.text}
              </Button>
            }
            {bannerData?.contentLayoutType == 'type-2' &&
              <AnchorLink linkLabel={bannerData?.cta?.text} link={bannerData?.cta?.url} />
            }
          </div>
        )}
      </div>
    </Tag>
  );
}

export default BannerListItem;
